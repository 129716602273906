import React, { useState } from "react";
import styles from "./CategoryItem.module.scss";
import trash from "../../assets/delete.png";
import { defaultUrl } from "../../utils/defaultUrl";
import editImg from "../../assets/editImg.png";
import saveImg from "../../assets/save.png";
import closeImg from "../../assets/closeImg.png";
import { fetchPutRequset } from "../../api/fetchPutRequest";

const CategoryItem = ({
  name,
  photo,
  description,
  item,
  setShowModal,
  setChosenId,
  setErrMessage,
  getIngridients,
  setIsUserInTheEnd,
}) => {
  const [edit, setEdit] = useState(false);

  const [newName, setNewName] = useState(name);
  const [newDescription, setNewDescription] = useState(description);
  const [imgFile, setImgFile] = useState();

  const onUpdateIngridient = async () => {
    setEdit(false);
    setIsUserInTheEnd(false);
    let formData = new FormData();
    formData.append("name", newName);
    formData.append("description", newDescription);
    formData.append("photo", imgFile);
    formData.append("id", item._id);
    const response = await fetchPutRequset("ingredient", formData);
    getIngridients();
  };

  // console.log(newDescription);
  return (
    <div className={styles.category__item}>
      <div className={styles.category__nameContainer}>
        {edit ? (
          <textarea
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        ) : (
          <p>{name}</p>
        )}
      </div>
      <div className={styles.category__description}>
        {edit ? (
          <textarea
            type="text"
            name="description"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        ) : (
          <p>{description}</p>
        )}
      </div>
      <div className={styles.category__imgBox}>
        {edit ? (
          <div
            style={{
              width: 102,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 50,
            }}
          >
            {!!imgFile ? (
              <img
                src={URL.createObjectURL(imgFile)}
                alt=""
                style={{ width: 50, height: 50 }}
              />
            ) : (
              <>
                <input
                  type="file"
                  onChange={(e) => setImgFile(e.target.files[0])}
                  style={{ width: 100 }}
                  id="files"
                />
              </>
            )}
          </div>
        ) : (
          <img
            src={`${defaultUrl}${photo}`}
            alt="Img"
            className={styles.category__icon}
          />
        )}
      </div>
      <div className={styles.category__edit}>
        {!edit ? (
          <img src={editImg} alt="" onClick={() => setEdit(true)} />
        ) : (
          <>
            <img src={saveImg} alt="" onClick={onUpdateIngridient} />
            <img
              src={closeImg}
              alt=""
              onClick={() => setEdit(false)}
              style={{ marginLeft: 10 }}
            />
          </>
        )}
      </div>
      <img
        src={trash}
        alt=""
        className={styles.category__trash}
        onClick={() => {
          setErrMessage("");
          setShowModal(true);
          setChosenId(item._id);
        }}
      />
    </div>
  );
};

export default CategoryItem;
