import axios from "axios";

const instance = axios.create({
  baseURL: "https://64.227.103.138:5000",
});

// instance.interceptors.response.use(
//   (res) => res,
//   (err) => {
//     if (err.response.data.visible == false || err.response.status == 500) {
//       alert("Something wrong server");
//     }
//     return Promise.reject(err);
//   }
// );

export default instance;
