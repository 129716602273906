import React from "react";

import styles from "./FormInput.module.scss";

const FormInput = ({
  value,
  onChange,
  placeholder,
  name,
  password = false,
}) => {
  return (
    <input
      type={password ? "password" : "text"}
      className={styles.formInput}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default FormInput;
