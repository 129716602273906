import { getToken } from "../utils/tokenStorage";

export const fetchUpdateRecipes = async (body) => {
  var myHeaders = new Headers();
  const token = await getToken();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify(body);

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "manual",
  };

  try {
    const response = await fetch(
      `https://couchbeautyplatform.com/recipe`,
      requestOptions
    );
    if (response.status === 200 || 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      const data = await response.json();
      console.log(data);
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      alert("Sorry the server is not available right now please try later");
    }
  } catch (e) {
    console.log(e);
  }
};
