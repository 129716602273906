import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import { Home, NotFound, SignIn } from "./pages";

function App() {
  const history = useHistory();

  React.useEffect(() => {
    history.push({ pathname: "/signIn" });
  }, []);
  return (
    <>
      <Switch>
        <Route exact path="/signIn" component={SignIn} />
        <Route path="/" component={Home} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
