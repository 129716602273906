import React from "react";
import { useHistory } from "react-router-dom";

import { auth } from "../../api/categories";
import { fetchPostRequest } from "../../api/fetchPostRequest";
import { FormInput, Button } from "../../components";
import { setToken } from "../../utils/tokenStorage";

import styles from "./SignIn.module.scss";

const SignIn = () => {
  const history = useHistory();
  const [data, setData] = React.useState({ login: "", password: "" });
  const [warning, setWarning] = React.useState(false);
  const onChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  //couchBAdmin
  //wvZQ9h8Qj
  const onSignIn = async () => {
    try{
      const { login, password } = data;
      if(login == "couchBAdmin" && password == "wvZQ9h8Qj"){
        const formdata = new FormData();
        formdata.append("login", "couchBAdmin");
        formdata.append("password", "wvZQ9h8Qj");
        const response = await fetchPostRequest("auth", formdata,false);
        console.log(response);
        if (response.hasOwnProperty("token")) {
          console.log(response.token)
          setToken(response.token);
            history.push({ pathname: "/overviewPage" });
        }
      }else{
        setWarning(true)
      }
    }catch(e){
      console.log(e)
    }
   
   

   

  
  };

  return (
    <div className={styles.signIn}>
      <div className={styles.signIn__inner}>
        <div className={styles.signIn__title}>Couch Beauty</div>
        <div className={styles.signIn__form}>
          <div className={styles.signIn__input}>
            <FormInput
              placeholder="Login"
              name="login"
              value={data.login}
              onChange={onChange}
            />
          </div>
          <div className={styles.signIn__input}>
            <FormInput
              placeholder="Password"
              name="password"
              value={data.password}
              onChange={onChange}
              password
            />
          </div>
          {warning && <p style={{textAlign:'center',color:'orange'}}>Email or Password is incorrect</p>}
          <div className={styles.signIn__btn}>
            <Button onClick={onSignIn}>
              <span>Sign In</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
