import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { CategoryItem } from "../../components";
import { Modal } from "../../components/Modal/Modal";

import Loader from "react-loader-spinner";
import styles from "./Category.module.scss";
import upload from "../../assets/upload.png";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { fetchPostRequest } from "../../api/fetchPostRequest";
import { fetchGetRequest } from "../../api/fetchGetRequest";

const Category = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const [ingridients, setIngridients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chosenId, setChosenId] = useState("");
  const [skip, setSkip] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [footerLoader, setFooterLoader] = useState(false);
  const [isUserInTheEnd, setIsUserInTheEnd] = useState(false);

  const getIngridients = async () => {
    setLoading(true);
    try {
      const response = await fetchGetRequest("ingredients/?skip=0&limit=10");
      setSkip(10);
      setIngridients(response.ingredients);
    } catch (err) {
      console.log(err.response);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      setSkip(10);
      console.log("DESCRIP", data.description);
      data.photo = data.photo[0];
      const formdata = new FormData();
      formdata.append("photo", data.photo);
      formdata.append("name", data.name);
      formdata.append(
        "description",
        data.description.length ? data.description : "No description"
      );
      const response = await fetchPostRequest("addIngredient", formdata, true);
      console.log(response);
      if (!!response && !!response.ingredients) {
        setIngridients(response.ingredients);
      } else {
        setErrMessage(response.message);
        setShowModal(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = async (id) => {
    setLoading(true);
    setSkip(10);
    setIsUserInTheEnd(false);
    try {
      const formdata = new FormData();
      formdata.append("id", id);
      const response = await fetchPostRequest(
        "deleteIngredient",
        formdata,
        true
      );
      if (!!response && response.ingredients) {
        setIngridients(response.ingredients);
        setShowModal(false);
      } else {
        setErrMessage("Can`t delete ingredient because it`s in use");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getIngridients();
  }, []);

  const [activeIconFile] = watch("photo") || [];

  const getMoreIngridients = async () => {
    await setSkip((prev) => prev + 10);
    setFooterLoader(true);
    const response = await fetchGetRequest(
      `ingredients/?skip=${skip}&limit=10`
    );
    !response.ingredients.length ? setIsUserInTheEnd(true) : setIsUserInTheEnd(false);
    setFooterLoader(false);
    if (!!response) {
      setIngridients((prev) => [...prev, ...response.ingredients]);
    }
  };

  return (
    <>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          deleteRecipeHandler={deleteCategory}
          id={chosenId}
          message={errMessage}
        />
      )}
      <div className={styles.category}>
        <div className={styles.category__start}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.category__form}
          >
            <button type="submit" className={styles.category__title}>
              Create ingridient
            </button>
            <div className={styles.category__fl_upld}>
              <input
                maxLength={19}
                placeholder="Ingridient name"
                {...register("name", { required: true })}
                className={styles.category__from_input}
              />
              {errors.name && (
                <p className={styles.category__error}>
                  field name category is required
                </p>
              )}
            </div>
            <div>
              <textarea
                placeholder="Ingridient description"
                {...register("description", { required: false })}
                className={styles.category__from_input2}
              />
              {errors.description && (
                <p className={styles.category__errorDeck}>
                  Description is required
                </p>
              )}
            </div>

            <div className={styles.category__fl_upld}>
              <label className={styles.category__label}>
                <input
                  className={styles.category__inputChoose}
                  type="file"
                  name="activeImg"
                  {...register("photo", { required: true })}
                />
                {activeIconFile ? (
                  <img
                    src={URL.createObjectURL(activeIconFile)}
                    className={styles.category__selectedIcon}
                    alt="selected Icon"
                  />
                ) : (
                  <>
                    <img
                      src={upload}
                      className={styles.category__uploadIcon}
                      alt="icon upload"
                    />
                    <p className={styles.category__text_choose_icon}>
                      choose icon of ingridient
                    </p>
                  </>
                )}
              </label>
              <div id="photo" className={styles.category__error}>
                {errors.photo && <p>file not selected</p>}
              </div>
            </div>

            <div className={styles.category__fl_upld}>
              <div id="inactivePhoto" className={styles.category__error}>
                {errors.inactivePhoto && <p>file not selected</p>}
              </div>
            </div>
          </form>
        </div>
        <div className={styles.category__inner}>
          <div className={styles.category__titleTable}>
            <div className={styles.category__titleTable_items}>
              <p>Ingridients name</p>
              <p>Description</p>
              <p>Icon</p>
            </div>
          </div>
          <div className={styles.category__list}>
            {loading ? (
              <div className={styles.category__loader}>
                <Loader type="BallTriangle" color={"#2684ff"} />
              </div>
            ) : (
              !!ingridients &&
              ingridients.map((item, index) => {
                return (
                  <CategoryItem
                    key={index}
                    name={item.name}
                    typeId={item.description}
                    photo={item.photo}
                    description={item.description}
                    deleteCategory={deleteCategory}
                    item={item}
                    setShowModal={setShowModal}
                    setChosenId={setChosenId}
                    setErrMessage={setErrMessage}
                    getIngridients={getIngridients}
                    setIsUserInTheEnd={setIsUserInTheEnd}
                  />
                );
              })
            )}
          </div>
        </div>
        <div className={styles.category__pagination}>
          {footerLoader && (
            <Loader
              type="BallTriangle"
              color={"#2684ff"}
              height={50}
              width={50}
            />
          )}
          <div
            className={styles.category__showMoreContainer}
            onClick={!isUserInTheEnd ? getMoreIngridients : null}
            style={isUserInTheEnd ? { backgroundColor: "#ccc" } : null}
          >
            <p>Show More</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
