import { getToken } from "../utils/tokenStorage";

export const fetchPostRequest = async (query, formdata, tokenFlag,contentType) => {
  const myHeaders = new Headers();

  if (!!tokenFlag) {
    const token = await getToken();
  
    myHeaders.append("Authorization", `Bearer ${token}`);
    !!contentType &&  myHeaders.append("Content-Type", "application/json");
  }
  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
    headers: myHeaders,
  };
  try {
    const response = await fetch(
      `https://couchbeautyplatform.com/${query}/`,
      requestOptions
    );
    if (response.status === 200 || 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    }else if (response.status === 401) {
      const data = await response.json();
      console.log(data);
    } 
     else if (response.status === 403) {
      return false;
    } 
    else if (response.status === 500) {
      alert("Sorry the server is not available right now please try later");
    }
  } catch (e) {
    console.log(e);
  }
};
