import React, { useState } from "react";
import trash from "../../assets/delete.png";
import { defaultUrl } from "../../utils/defaultUrl";
import "./style.scss";
import editIcon from "../../assets/editImg.png";
import saveIcon from "../../assets/save.png";
import { fetchPutRequset } from "../../api/fetchPutRequest";
import { fetchUpdateRecipes } from "../../api/fetchUpdateRecipes";

export const RecipeCard = ({
  item,
  setShowModal,
  setChosenId,
  setErrMessage,
  getRecipes,
  setLoading,
  setIsUserInTheEnd
}) => {
  const { category, directions, ingredients, photo, recipeName, _id } = item;

  const [isEditable, setIsEditable] = useState(false);
  const [newName, setNewName] = useState(recipeName);
  const [newCategories, setNewCategories] = useState(category);
  const [newDescription, setNewDesciption] = useState(directions);

  const checkBoxHandler = (targetName) => {
    if (newCategories.includes(targetName)) {
      setNewCategories((prev) => prev.filter((el) => el !== targetName));
    } else setNewCategories((prev) => [...prev, targetName]);
  };

  const onUpdateRecipe = async () => {
    setIsEditable(false);
    setLoading(true);
    setIsUserInTheEnd(false)
    const body = {
      id: _id,
      recipe: {
        category: newCategories,
        directions: newDescription,
        recipeName: newName,
      },
    };
    const updatedResponse = await fetchUpdateRecipes(body);
    console.log(updatedResponse, "Updated resp")
    await getRecipes();
 
   
  };

  return (
    <>
      {isEditable ? (
        <div className="main_wrapper">
          <textarea
            className="name"
            value={newName}
            onChange={({ target: { value } }) => setNewName(value)}
          />
          <div className="categories">
            <div className="checkBox_container">
              <input
                type="checkbox"
                name="combo"
                onChange={({ target }) => checkBoxHandler(target.name)}
                checked={newCategories.includes("combo")}
              />
              <p>Combo Skin</p>
            </div>
            <div className="checkBox_container">
              <input
                type="checkbox"
                name="oily"
                onChange={({ target }) => checkBoxHandler(target.name)}
                checked={newCategories.includes("oily")}
              />
              <p>Oily Skin</p>
            </div>
            <div className="checkBox_container">
              <input
                type="checkbox"
                name="sensitive"
                onChange={({ target }) => checkBoxHandler(target.name)}
                checked={newCategories.includes("sensitive")}
              />
              <p>Sensitive Skin</p>
            </div>
            <div className="checkBox_container">
              <input
                type="checkbox"
                name="vegan"
                onChange={({ target }) => checkBoxHandler(target.name)}
                checked={newCategories.includes("vegan")}
              />
              <p>Vegan Recipes</p>
            </div>
            <div className="checkBox_container">
              <input
                type="checkbox"
                name="dry"
                onChange={({ target }) => checkBoxHandler(target.name)}
                checked={newCategories.includes("dry")}
              />
              <p>Dry Skin</p>
            </div>
            <div className="checkBox_container">
              <input
                type="checkbox"
                name="adjourn aging"
                onChange={({ target }) => checkBoxHandler(target.name)}
                checked={newCategories.includes("adjourn aging")}
              />
              <p> Adjourn Aging</p>
            </div>
            <div className="checkBox_container">
              <input
                type="checkbox"
                name="acne prone"
                value={true}
                onChange={({ target }) => checkBoxHandler(target.name)}
                checked={newCategories.includes("acne prone")}
              />
              <p> Acne Prone Skin</p>
            </div>
          </div>
          <textarea
            className="description"
            value={newDescription}
            onChange={({ target: { value } }) => setNewDesciption(value)}
          />
          <div className="ingridientsChange">
            {ingredients.map((el, index) => (
              <div key={index} className="ingridients_wrapper">
                <img src={`${defaultUrl}${el.photo}`} alt="" />
                {el.name}
              </div>
            ))}
          </div>

          <div className="options_container">
            <img src={saveIcon} alt="" onClick={onUpdateRecipe} />
            <img
              src={trash}
              alt="delete"
              onClick={() => {
                setErrMessage("");
                setChosenId(_id);
                setShowModal(true);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="main_wrapper">
          <div className="name">{recipeName}</div>
          <div className="categories">
            {category.map((el, index) => (
              <p key={index}>• {el}</p>
            ))}
          </div>
          <div className="description">{directions}</div>
          <div className="ingridients">
            {ingredients.map((el, index) => (
              <div key={index} className="ingridients_wrapper">
                <img src={`${defaultUrl}${el.photo}`} alt="" />
                {el.name}
              </div>
            ))}
          </div>

          <div className="options_container">
            <img src={editIcon} alt="" onClick={() => setIsEditable(true)} />
            <img
              src={trash}
              alt="delete"
              onClick={() => {
                setErrMessage("");
                setChosenId(_id);
                setShowModal(true);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
