import React from "react";
import "./styles.scss";
import close from "../../assets/closeImg.png";
import save from "../../assets/save.png";

export const Modal = ({ setShowModal, deleteRecipeHandler, id, message }) => {
  return (
    <div className="modalDelete" onClick={() => {}}>
      <div className="modalDelete__form2">
        <div className="modalDelete__imgBox">
          <img src={close} alt="close" onClick={() => setShowModal(false)} />
        </div>
        {!message ? (
          <>
            <div style={{ fontSize: 20, textAlign: "center" }}>
              Are you sure ?
            </div>
            <div className="save_container">
              <img
                src={save}
                alt=""
                className="save_img"
                onClick={() => {
                  deleteRecipeHandler(id);
                }}
              />
            </div>
          </>
        ) : (
          <div style={{ textAlign: "center", marginTop: 10 }}>{message}</div>
        )}
      </div>
    </div>
  );
};
