import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IngridientCard } from "./IngridientCard";
import Loader from "react-loader-spinner";
import styles from "./Recipes.module.scss";
import "./card.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { fetchGetRequest } from "../../api/fetchGetRequest";
import { ChosenIngridien } from "../../components/ChosenIngridients/ChosenIngridien";
import { RecipeCard } from "../../components/RecipeCard/RecipeCard";
import { fetchPostRequest } from "../../api/fetchPostRequest";
import { Modal } from "../../components/Modal/Modal";
const Recipes = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const [arrIngredients, setArrIngredients] = useState([]);
  const [isShownIngridients, setIsShownIngridients] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chosen, setChosen] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [chosenCategory, setChosenCategory] = useState([]);
  const [chosenId, setChosenId] = useState("");
  const [skip, setSkip] = useState(10);
  const [chosenIngridientsWarning, setChosenIngridientsWarning] =
    useState(false);
  const [chosenCategoryWarning, setChosenCategoryWarning] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [footerLoader, setFooterLoader] = useState(false);
  const [isUserInTheEnd, setIsUserInTheEnd] = useState(false);

  const getRecipes = async () => {
    setLoading(true);
    setSkip(10);
    try {
      const response = await fetchGetRequest("recipes?skip=0&limit=10");
      console.log(response, "<<<---------------------------");
      setRecipes(response.recipes);
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    !chosen.length
      ? setChosenIngridientsWarning(true)
      : setChosenIngridientsWarning(false);
    !chosenCategory.length
      ? setChosenCategoryWarning(true)
      : setChosenCategoryWarning(false);
    const { name, description, photo } = data;
    if (chosen.length && chosenCategory.length) {
      const newChosen = chosen.map((el) => {
        return { amount: el.amount, ingredient: el._id };
      });
      const body = {
        recipeName: name,
        ingredients: newChosen,
        category: chosenCategory,
        directions: description,
      };
      console.log(body, "BODY");
      setLoading(true);
      try {
        const response = await fetchPostRequest(
          "addRecipe",
          JSON.stringify(body),
          true,
          true
        );
        if (!!response && !!response.recipes) {
          setIsShownIngridients(false);
          setChosen([]);
          setRecipes(response.recipes);
        } else {
          setShowModal(true);
          setErrMessage(response.message);
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
  };

  const getIngridients = async () => {
    const resp = await fetchGetRequest("ingredients/");
    setArrIngredients(resp.ingredients);
  };

  const searchIngridient = (text) => {
    if (text.length) {
      setIsShownIngridients(true);
      const searchable = arrIngredients.filter((el) =>
        el.name.toLowerCase().includes(text.toLowerCase())
      );
      setArrIngredients(searchable);
    } else {
      getIngridients();
    }
  };

  const deleteAlreadyChosenIngridient = (id) => {
    const filtered = chosen.filter((el) => el._id !== id);
    setChosen(filtered);
  };

  const addCategoryHandler = (target) => {
    if (target.checked) {
      setChosenCategory((prev) =>
        prev.includes(target.name) ? [...prev] : [...prev, target.name]
      );
    } else {
      const filtered = chosenCategory.filter((el) => el !== target.name);
      setChosenCategory(filtered);
    }
  };

  const deleteRecipeHandler = async (id) => {
    const formdata = new FormData();
    formdata.append("id", id);
    setSkip(10);
    setLoading(true);
    setIsUserInTheEnd(false);
    try {
      const response = await fetchPostRequest("deleteRecipe", formdata, true);
      if (!!response && !!response.recipes) {
        setRecipes(response.recipes);
        setShowModal(false);
      } else {
        setErrMessage(response.message);
        setShowModal(true);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getIngridients();
    getRecipes();
  }, []);

  const getMoreRecipes = async () => {
    setFooterLoader(true);
    await setSkip((prev) => prev + 10);
    const response = await fetchGetRequest(`recipes/?skip=${skip}&limit=10`);
    setFooterLoader(false);
    console.log(response.recipes,"<---")
    !response.recipes.length && setIsUserInTheEnd(true);
    console.log(response, "response");
    if (!!response) {
      setRecipes((prev) => [...prev, ...response.recipes]);
    }
  };

  return (
    <>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          deleteRecipeHandler={deleteRecipeHandler}
          id={chosenId}
          message={errMessage}
        />
      )}
      <div className={styles.category}>
        <div className={styles.category__start}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.category__form}
          >
            <button type="submit" className={styles.category__title}>
              Create recipe
            </button>
            <div className={styles.category__fl_upld}>
              <input
                placeholder="Recipe name"
                {...register("name", { required: true })}
                className={styles.category__from_input}
                style={
                  errors.name
                    ? { borderColor: "red" }
                    : { borderColor: "black" }
                }
              />
              {errors.name && (
                <p className={styles.category__error}>
                  field name category is required
                </p>
              )}
            </div>
            <textarea
              placeholder="Recipe description"
              {...register("description", { required: true })}
              className={styles.category__from_input2}
              style={
                errors.description
                  ? { borderColor: "red" }
                  : { borderColor: "black" }
              }
            />
            <div className={styles.category__check_box_main_wrapper}>
              <h3
                style={
                  chosenCategoryWarning ? { color: "red" } : { color: "black" }
                }
              >
                Choose Category
              </h3>
              <div>
                <input
                  type="checkbox"
                  name="combo"
                  className={styles.category__check_box_wrap}
                  onChange={({ target }) => addCategoryHandler(target)}
                />
                Combo Skin
              </div>
              <div>
                <input
                  type="checkbox"
                  name="oily"
                  className={styles.category__check_box_wrap}
                  onChange={({ target }) => addCategoryHandler(target)}
                />
                Oily Skin
              </div>
              <div>
                <input
                  type="checkbox"
                  name="sensitive"
                  className={styles.category__check_box_wrap}
                  onChange={({ target }) => addCategoryHandler(target)}
                />
                Sensitive Skin
              </div>
              <div>
                <input
                  type="checkbox"
                  name="vegan"
                  className={styles.category__check_box_wrap}
                  onChange={({ target }) => addCategoryHandler(target)}
                />
                Vegan Recipes
              </div>
              <div>
                <input
                  type="checkbox"
                  name="dry"
                  className={styles.category__check_box_wrap}
                  onChange={({ target }) => addCategoryHandler(target)}
                />
                Dry Skin
              </div>
              <div>
                <input
                  type="checkbox"
                  name="adjourn aging"
                  className={styles.category__check_box_wrap}
                  onChange={({ target }) => addCategoryHandler(target)}
                />
                Adjourn Aging
              </div>
              <div>
                <input
                  type="checkbox"
                  name="acne prone"
                  className={styles.category__check_box_wrap}
                  onChange={({ target }) => addCategoryHandler(target)}
                />
                Acne Prone Skin
              </div>
            </div>
            <div className={styles.category__ingridients_main_box}>
              <div>
                <div className={styles.category__show}>
                  <input
                    placeholder="Search Ingridients"
                    className={styles.category__search_ingridient}
                    onChange={({ target: { value } }) =>
                      searchIngridient(value)
                    }
                  />
                  <div
                    className={styles.category__show_btn}
                    onClick={() => setIsShownIngridients((prev) => !prev)}
                  >
                    {!isShownIngridients ? "Show" : "Hide"}
                  </div>
                </div>
              </div>
              {!!arrIngredients &&
                isShownIngridients &&
                arrIngredients.map((el, index) => {
                  return (
                    <IngridientCard
                      key={index}
                      item={el}
                      setChosen={setChosen}
                      chosen={chosen}
                    />
                  );
                })}
            </div>
            <div className={styles.category__chosen_products_box}>
              <h4
                style={
                  chosenIngridientsWarning
                    ? { color: "red" }
                    : { color: "black" }
                }
              >
                Chosen ingridients
              </h4>
              {chosen.map((item, index) => (
                <ChosenIngridien
                  key={index}
                  item={item}
                  setChosen={setChosen}
                  chosen={chosen}
                  deleteAlreadyChosenIngridient={deleteAlreadyChosenIngridient}
                />
              ))}
            </div>
          </form>
        </div>
        <div className={styles.category__inner}>
          <div className={styles.category__titleTable}>
            <div className={styles.category__titleTable_items}>
              <div style={{ marginRight: 80 }}>Recipes name</div>
              <div style={{ marginRight: 80 }}>Category</div>
              <p>Description</p>
              <div>Ingridients</div>
              <div style={{ width: 40 }}></div>
            </div>
          </div>
          <div className={styles.category__list}>
            {loading ? (
              <div className={styles.category__loader}>
                <Loader type="BallTriangle" color={"#2684ff"} />
              </div>
            ) : (
              !!recipes &&
              recipes.map((item, index) => {
                return (
                  <RecipeCard
                    key={index}
                    item={item}
                    setRecipes={setRecipes}
                    setLoading={setLoading}
                    setShowModal={setShowModal}
                    setChosenId={setChosenId}
                    setErrMessage={setErrMessage}
                    getRecipes={getRecipes}
                    setLoading={setLoading}
                    setIsUserInTheEnd={setIsUserInTheEnd}
                  />
                );
              })
            )}
          </div>
        </div>
        <div className={styles.category__pagination}>
          {footerLoader && (
            <Loader
              type="BallTriangle"
              color={"#2684ff"}
              height={50}
              width={50}
            />
          )}
          <div
            className={styles.category__show_more}
            onClick={!isUserInTheEnd ? getMoreRecipes : null}
            style={isUserInTheEnd ? { backgroundColor: "#ccc" } : null}
          >
            show more
          </div>
        </div>
      </div>
    </>
  );
};

export default Recipes;
