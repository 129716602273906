import React, { useState, useEffect } from "react";
import "./card.scss";
import plus from "../../assets/plus.png";
import { defaultUrl } from "../../utils/defaultUrl";

export const IngridientCard = ({
  chosen,
  setChosen,
  item,
  item: { name, photo },
}) => {

  
  const addToChosen = () => {
    setChosen((prev) => (prev.includes(item) ? [...prev] : [...prev, item]));
  };

  return (
    <div className="main_container" onClick={() => addToChosen()}>
      <div>
        <img
          src={`${defaultUrl}${photo}`}
          style={{ width: 36, height: 36, marginRight: 20 }}
        />
      </div>
      <div>{name}</div>
      <div>
        <img src={plus} style={{ width: 36, height: 36 }} />
      </div>
    </div>
  );
};
