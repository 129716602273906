import React from "react";

import styles from "./Button.module.scss";

const Button = ({ children, customStyle, onClick }) => {
  return (
    <button
      className={styles.button}
      style={{ ...customStyle }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
