import React from "react";

import styles from "./CaseItem.module.scss";

const CaseItem = ({ name, ingredientsAmount }) => {
  console.log(ingredientsAmount)
  return (
    <div className={styles.case__item}>
      <h3>{name}</h3>
      <h2>{ingredientsAmount}</h2>
    </div>
  );
};

export default CaseItem;
