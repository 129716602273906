import React, { useState } from "react";
import "./styles.scss";
import trash from "../../assets/delete.png";
import save from "../../assets/save.png";
import { defaultUrl } from "../../utils/defaultUrl";
export const ChosenIngridien = ({
  deleteAlreadyChosenIngridient,
  item,
  setChosen,
  chosen,
  index,
}) => {
  const [readable, setIsReadble] = useState(false);

  const [text, setText] = useState("");
  const [textWarning, setTextWarning] = useState(false);

  const addAmount = () => {
    if (text.length) {
      setChosen((prev) =>
        prev.map((elem) => {
          if (elem._id === item._id) {
            return { ...elem, amount: text };
          }
          return elem;
        })
      );
      setIsReadble(true);
      setTextWarning(false)
    } else {
      setTextWarning(true)
    }
  };

  return (
    <div
      className="main"
      style={readable ? { backgroundColor: "lightgreen" } : null}
    >
      <div className="img_box">
        <img
          src={`${defaultUrl}${item.photo}`}
          alt="ingridient"
          className="img_ingridient"
        />
        <p>{item.name}</p>
        <div className="confirm_box">
          <img
            src={trash}
            alt="trash"
            className="img"
            onClick={() => deleteAlreadyChosenIngridient(item._id)}
          />
          <img src={save} alt="save" className="img" onClick={addAmount} />
        </div>
      </div>
      <textarea
        className={textWarning ? 'warning' : 'text'}
        name=""
        id=""
        cols="30"
        rows="3"
        placeholder="Numbers of ingridient"
        readOnly={readable}
        style={readable ? { backgroundColor: "lightgreen" } : null}
        value={text}
        onChange={(e) => setText(e.target.value)}
        
      ></textarea>
    </div>
  );
};
