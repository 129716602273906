import React, { useState, useEffect } from "react";

import { CaseItem } from "../../components";
import styles from "./Overview.module.scss";
import { fetchGetRequest } from "../../api/fetchGetRequest";

const Overview = () => {
  const [cases, setCases] = useState([]);

  const getStatistic = async () => {
    const response = await fetchGetRequest("getStatistic");

    if (!!response) {
      setCases(response);
      console.log(response);
    }
  };

  useEffect(() => getStatistic(), []);

  return (
    <div className={styles.overview}>
      <div className={styles.overview__case}>
        {!!cases && (
          <>
            <CaseItem
              name={"Ingredients"}
              ingredientsAmount={cases.ingredientsAmount}
            />
            <CaseItem
              name={"Recipes"}
              ingredientsAmount={cases.recipesAmount}
            />
            <CaseItem name={"Users"} ingredientsAmount={cases.usersAmount} />
          </>
        )}
      </div>
    </div>
  );
};

export default Overview;
